<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<form v-else class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<CRow class="mb-5">
			<!-- Category level -->
			<CCol lg="12">
				<h4 class="mb-4">
					Category level
				</h4>
				<p class="label level-label">
					Creating category
				</p>
				<BaseButtonGroup
					v-model="level"
					:list="CATEGORY_LEVEL_OPTIONS"
					class="mb-4"
					@input="handleSelectedLevel"
				/>
				<CRow v-if="isParentSelectable" class="row-parent-category">
					<CCol md="6">
						<BaseDropDown
							v-model="$v.categoryParentLevelOne.$model"
							:options="categoryParentLevelOneOptions"
							:searchable="false"
							:is-valid="isValidCategoryParentLevelOne"
							:invalid-feedback="validatecategoryParentLevelOneMessage"
							label-drop-down="Under parent category (level 1)*"
							label="name"
							track-by="value"
							placeholder="Selection"
							class="select-custom"
							test-id="category-level-one-dropdown"
							@input="handleSelectedCategoryParentLevelOne"
						/>
					</CCol>
					<CCol v-if="isLevelThree" md="6">
						<BaseDropDown
							v-model="$v.categoryParentLevelTwo.$model"
							:options="categoryParentLevelTwoOptions"
							:searchable="false"
							:is-valid="isValidCategoryParentLevelTwo"
							:invalid-feedback="validatecategoryParentLevelTwoMessage"
							:disabled="!categoryParentLevelOne"
							label-drop-down="Under parent category (level 2)*"
							label="name"
							track-by="value"
							placeholder="Selection"
							class="select-custom"
							test-id="category-level-two-dropdown"
							@input="clearError"
						/>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Category level -->

			<!-- General info -->
			<CCol lg="12">
				<h4 class="mb-4">
					General info
				</h4>
				<CRow>
					<CCol md="6">
						<CInput
							v-model.trim="$v.name.$model"
							data-id="category-name-en"
							:is-valid="isValidName"
							:invalid-feedback="validateNameMessage"
							label="Category name (EN)*"
							@input="clearError"
							@blur="handleAutoFillMetaTitleAndSlug"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="$v.nameTH.$model"
							data-id="category-name-th"
							:is-valid="isValidNameTH"
							:invalid-feedback="validateNameTHMessage"
							label="Category name (TH)*"
							@input="clearError"
							@blur="handleAutoFillMetaTitleTH"
						/>
					</CCol>
					<CCol md="12">
						<CInput
							v-model.trim="$v.slug.$model"
							data-id="category-slug"
							:is-valid="isValidSlug"
							:invalid-feedback="validateSlugMessage"
							:description="slugHelperText"
							label="Slug"
							@input="clearError"
						/>
					</CCol>
					<CCol md="12">
						<CInput
							v-model.trim="$v.customUrl.$model"
							:is-valid="!$v.customUrl.$error && null"
							:invalid-feedback="$t('global.error.url')"
							:description="'Allow only url. example: https://www.studio7thailand.com'"
							label="Custom URL"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="commission"
							data-id="category-commission"
							label="Commission (%)"
							@input="clearError"
						/>
					</CCol>
					<CCol xl="12" class="d-flex align-items-center margin-bottom-32">
						<label class="toggle-label" for="category-status">
							Category status
						</label>
						<CSwitch
							id="category-status"
							data-id="category-status"
							:checked="status"
							variant="3d"
							size="sm"
							class="switch-custom toggle-status"
							color="success"
							@update:checked="handleUpdateStatus"
						/>
						<span>{{ statusText }}</span>
					</CCol>
					<template v-if="isParentSelectable">
						<CCol
							xl="12"
							class="d-flex align-items-center margin-bottom-32"
						>
							<label class="toggle-label" for="category-is-new">
								New category visibility <base-tooltip :message="$t('page.categories.tooltip.isNew')" />
							</label>
							<CSwitch
								id="category-is-new"
								data-id="category-is-new"
								:checked="isNew"
								variant="3d"
								size="sm"
								class="switch-custom toggle-status"
								color="success"
								@update:checked="handleUpdateIsNew"
							/>
							<span>{{ isNewText }}</span>
						</CCol>
					</template>
					<CCol
						xl="12"
						class="d-flex align-items-center margin-bottom-32"
					>
						<label class="toggle-label" for="category-is-visibility-all">
							View all visibility <base-tooltip :message="$t('page.categories.tooltip.visibleViewAll')" />
						</label>
						<CSwitch
							id="category-is-visibility-all"
							data-id="category-is-visibility-all"
							:checked="isVisibleViewAll"
							variant="3d"
							size="sm"
							class="switch-custom toggle-status"
							color="success"
							@update:checked="handleUpdateIsVisibleViewAll"
						/>
						<span>{{ visibleViewAllText }}</span>
					</CCol>
					<CCol xl="12" class="d-flex align-items-center margin-bottom-32">
						<label class="toggle-label" for="category-visibility">
							Storefront visibility
						</label>
						<CSwitch
							id="category-visibility"
							data-id="category-visibility"
							:checked="visibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
							@update:checked="handleUpdateVisibility"
						/>
						<span>{{ visibilityText }}</span>
					</CCol>
					<CCol xl="12" class="d-flex align-items-center margin-bottom-32">
						<label class="toggle-label" for="navbar-visibility">
							Navbar visibility <base-tooltip :message="$t('page.categories.tooltip.navbarVisibility')" />
						</label>
						<CSwitch
							id="navbar-visibility"
							:checked="navbarVisibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
							@update:checked="handleUpdateNavbarVisibility"
						/>
						<span>{{ navbarVisibilityText }}</span>
					</CCol>
					<!-- hide for temporary -->
					<CCol v-if="false" xl="12" class="d-flex align-items-center">
						<label class="toggle-label" for="true-trade-in-visibility">
							TRUE / Trade in visibility <base-tooltip :message="$t('page.categories.tooltip.visibilityTradeIn')" />
						</label>
						<CSwitch
							id="true-trade-in-visibility"
							data-id="true-trade-in-visibility"
							:checked="tradeInTruemoveVisibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
							@update:checked="handleUpdateTradeInTruemoveVisibility"
						/>
						<span>{{ visibilityTradeInText }}</span>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end General info -->

			<!-- Compare -->
			<CCol lg="12">
				<h4 class="mb-4">
					Product compare
				</h4>
				<CRow>
					<CCol md="12" class="mb-3">
						{{ $t("page.categories.edit.productCompare") }}
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="compareEN"
							data-id="compare-text-en"
							label="Compare text (EN)"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="compareTH"
							data-id="compare-text-th"
							label="Compare text (TH)"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="compareDescriptionEN"
							data-id="compare-text-en"
							label="Compare description (EN)"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="compareDescriptionTH"
							data-id="compare-text-th"
							label="Compare description (TH)"
							@input="clearError"
						/>
					</CCol>
				</CRow>
				<CRow>
					<CCol md="7">
						<h6>
							Product Compare additional content
						</h6>
						<div class="description typo-helper-text color-black-45">
							Product compare will be displayed at the bottom of the product detail page
						</div>
					</CCol>
					<CCol md="5" class="text-right">
						<CButton
							color="secondary"
							class="btn btn-secondary text-nowrap"
							@click.prevent="$refs['modal-category-compare'].open()"
						>
							Add Compare
						</CButton>
					</CCol>
					<CCol md="12">
						<div class="compare-list mt-3" :class="emptyClasses">
							<draggable
								v-if="htmlContents.length"
								:value="htmlContents"
								tag="ul"
								ghost-class="is-placeholder-item"
								class="compare-list-dragable"
								@input="handleCompareOrder"
							>
								<CompareItem
									v-for="(data, index) in htmlContents"
									:key="index"
									:data="data"
									class="compare-list-item"
									@onMoveToTop="moveCompareToTop(index)"
									@onEdit="handleOnEditCompare(data, index)"
									@onDelete="handleOnDeleteCompare(index)"
								/>
							</draggable>
							<template v-else>
								<span class="subtitle color-black-45">No compare in the list</span>
							</template>
						</div>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Compare -->

			<!-- Slide category -->
			<CCol v-if="isLevelOne">
				<h4 class="mb-4">
					Category slide banner
				</h4>
				<CRow>
					<CCol md="12" class="mb-3">
						{{ $t("page.categories.edit.categorySlideBanner") }}
					</CCol>
					<CCol
						xl="12"
						class="d-flex align-items-center margin-bottom-32"
					>
						<label class="toggle-label" for="banner-visibility">
							Storefront visibility
						</label>
						<CSwitch
							id="banner-visibility"
							data-id="banner-visibility"
							:checked="isBannerVisibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
							@update:checked="handleUpdateBannerVisibility"
						/>
						<span>{{ bannerVisibilityText }}</span>
					</CCol>
					<CCol
						xl="12"
						class="d-flex align-items-center"
					>
						<label class="toggle-label" for="lowest-price-visibility">
							Price visibility
						</label>
						<CSwitch
							id="lowest-price-visibility"
							data-id="lowest-price-visibility"
							:checked="isLowestPriceVisibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
							@update:checked="handleUpdateLowestPriceVisibility"
						/>
						<span>{{ lowestPriceVisibilityText }}</span>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Slide category -->

			<!-- Product families -->
			<CCol v-if="!isLevelThree" lg="12">
				<h4 class="mb-4">
					Product Families
				</h4>
				<CRow class="product-famillies">
					<CCol xl="12" class="mb-3">
						{{ $t("page.categories.edit.addProductFamiles") }}
					</CCol>
					<CCol xl="12" md="6">
						<CButton
							color="secondary"
							class="transparent"
							data-test-id="product-families-group-add"
							@click="$refs['modal-product-families-group'].open()"
						>
							Manage product families
						</CButton>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Product families -->

			<!-- Badge Labels -->
			<!-- <CCol lg="12">
				<h4 class="mb-4">
					Badge Labels
				</h4>
				<CRow class="mb-2">
					<CCol xl="12">
						<ProductEditLabelList
							:type="PRODUCT_LABEL_TYPES.badge"
							:list="badgeLabels"
							:disabled="disableAddBadgeLabelButton"
							title="Badge label"
							description="Badge label will display on top category title on Product Families/LOB Pages"
							@onAdd="openProductBadgeModal(PRODUCT_LABEL_TYPES.badge)"
							@onDelete="handleDeleteBadgeLabel"
						/>
					</CCol>
				</CRow>
				<hr class="mt-4 mb-5">
			</CCol> -->
			<!-- end Badge Labels -->

			<!-- LOB/PLP -->
			<CCol lg="12">
				<h4 class="mb-4">
					Category Redirection
				</h4>
				<CRow class="mb-3">
					<CCol>
						This section will only affect categories on the navbar and category items on the Family Stripe
					</CCol>
				</CRow>
				<CRow>
					<CCol
						xl="12"
						class="d-flex align-items-center"
					>
						<BaseDropDown
							v-model="categoryDisplayType"
							class="w-100"
							:options="CATEGORY_DISPLAY_TYPE_OPTIONS"
							label="label"
							label-drop-down="Category Display"
							placeholder="Select category display type"
						/>
					</CCol>
					<CCol v-if="categoryDisplayType.value === CATEGORY_DISPLAY_TYPE.SEARCH_LIST" md="12">
						<CInput
							v-model.trim="searchKeyword"
							description="search keyword for type search"
							label="Search Keyword"
						/>
					</CCol>
				</CRow>
				<hr class="mt-4 mb-5">
			</CCol>
			<CCol lg="12">
				<h4 class="mb-4">
					Category Content
				</h4>
				<CRow>
					<CCol>
						<CategoryContentAttaches
							:items="localCategoryContentItems"
							title="Category content"
							description="These products/categories are will show when set and enable LOB/PLP in categories."
							is-draggable
							:display-type="categoryDisplayType.value"
							@update:items="(value) => localCategoryContentItems=value"
							@onDragged="(newList) => localCategoryContentItems=newList"
						/>
					</CCol>
				</CRow>
				<hr class="mt-4 mb-5">
			</CCol>
			<!-- end LOB/PLP -->

			<!-- Content -->
			<CCol lg="12">
				<h4 class="mb-4">
					{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.CONTENT] }}
				</h4>
				<CRow>
					<CCol md="12">
						<CategoryContentBannerAttaches
							:banners="banners"
							@update:banners="banners = $event"
						/>
					</CCol>
				</CRow>
				<CRow class="mt-4">
					<CCol lg="12">
						<label>Category description (top)</label>
						<div class="d-flex mb-2">
							<label class="toggle-label -is-expand-content" for="category-auto-expand">
								Auto expand content
							</label>
							<CSwitch
								id="category-auto-expand"
								:checked="isExpand"
								variant="3d"
								size="sm"
								class="switch-custom toggle-visibility"
								color="success"
								@update:checked="handleUpdateExpand"
							/>
							<span>{{ expandText }}</span>
						</div>
						<BaseRichTextEditor
							v-model="descriptionTop"
							name="descriptionTop"
							data-id="description-top"
						/>
					</CCol>
				</CRow>
				<CRow class="mt-2">
					<CCol lg="12">
						<label>Category description (bottom)</label>
						<BaseRichTextEditor
							v-model="descriptionBottom"
							name="descriptionBottom"
							data-id="description-bottom"
						/>
					</CCol>
				</CRow>
				<hr class="mt-4 mb-5">
			</CCol>
			<!-- end Content -->

			<!-- SEO -->
			<CCol lg="12">
				<h4 class="mb-4">
					SEO
				</h4>
				<CRow>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaTitle"
							data-id="meta-id-en"
							label="Meta title (EN)"
							rows="2"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaTitleTH"
							data-id="meta-id-th"
							label="Meta title (TH)"
							rows="2"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaKeyword"
							data-id="meta-keyword-en"
							label="Meta keywords (EN)"
							rows="3"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaKeywordTH"
							data-id="meta-keyword-th"
							label="Meta keywords (TH)"
							rows="3"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaDescription"
							data-id="meta-description-en"
							label="Meta description (EN)"
							rows="6"
							class="mb-0"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaDescriptionTH"
							data-id="meta-description-th"
							label="Meta description (TH)"
							rows="6"
							class="mb-0"
							@input="clearError"
						/>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end SEO -->
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isCreating"
			content-class="main-wrapper"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'CategoryLists' })"
		/>

		<!-- modal product compare -->
		<ModalCategoryCompare
			ref="modal-category-compare"
			@onConfirm="handleCategoryCompareChange"
		/>
		<ModalProductFamiliesGroup
			ref="modal-product-families-group"
			:list="productFamiliesGroup"
			@onConfirm="handleProductFamiliesGroupListChange"
		/>
	</form>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { required, helpers, url } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import CompareItem from '@/components/CompareItem.vue';
import ModalCategoryCompare from '@/components/ModalCategoryCompare.vue';
import ModalProductFamiliesGroup from '@/components/ModalProductFamiliesGroup.vue';
import CategoryContentAttaches from '@/components/CategoryContentAttaches.vue';
import CategoryContentBannerAttaches from '@/components/CategoryContentBannerAttaches.vue';
import { CATEGORY_LEVEL,
	CATEGORY_LEVEL_OPTIONS,
	CATEGORY_DISPLAY_TYPE_OPTIONS,
	CATEGORY_REFS,
	CATEGORY_REFS_TITLE,
	CATEGORY_DISPLAY_TYPE } from '../enums/categories';
import errorMessage from '../enums/errorMessage';
import { pathOr, scrollToTop, convertDateTimeToUTC } from '../assets/js/helpers';
import { transformedCategoryDropdownOption } from '../assets/js/transform/category';
import slug from '../regex/slug';
import {
	STATUS as mappingStatus,
	VISIBILITY as mappingVisibility,
	EXPAND as mappingExpand,
} from '../mapping/categories';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

const slugify = require('slugify');

export default {
	name: 'CategoryCreatePage',

	components: {
		draggable,
		CompareItem,
		ModalCategoryCompare,
		ModalProductFamiliesGroup,
		CategoryContentAttaches,
		CategoryContentBannerAttaches,
	},

	validations() {
		// NOTE: Use code like this for use variable
		return {
			categoryParentLevelOne: {
				required: (value) => {
					if (this.isParentSelectable) {
						return required(value);
					}
					return true; // Pass when level 1
				},
			},
			categoryParentLevelTwo: {
				required: (value) => {
					if (this.isLevelThree) {
						return required(value);
					}
					return true; // Pass when level 1 & 2
				},
			},
			name: {
				required,
			},
			nameTH: {
				required,
			},
			slug: {
				required,
				format: helpers.regex('slug', slug),
				duplicate: () => !this.create.fieldErrors.slug,
			},
			customUrl: {
				url,
			},
			link: {
				url,
			},
		};
	},
	data() {
		return {
			// Level
			level: CATEGORY_LEVEL.ONE,
			CATEGORY_LEVEL_OPTIONS,
			categoryParentLevelOne: null,
			categoryParentLevelTwo: null,
			CATEGORY_REFS,
			CATEGORY_REFS_TITLE,

			// General
			name: '',
			nameTH: '',
			slug: '',
			customUrl: null,
			commission: '',

			// Compare
			htmlContents: [],
			link: null,
			compareEN: null,
			compareTH: null,
			compareDescriptionEN: null,
			compareDescriptionTH: null,

			// Status
			isNew: false,
			isVisibleViewAll: false,
			status: true,
			visibility: false,
			navbarVisibility: false,
			tradeInTruemoveVisibility: false,
			isExpand: false,

			// More info
			descriptionTop: '',
			descriptionBottom: '',

			// SEO
			metaTitle: '',
			metaTitleTH: '',
			metaKeyword: '',
			metaKeywordTH: '',
			metaDescription: '',
			metaDescriptionTH: '',

			// Media
			banners: [],
			icon: null,

			// Category banner
			isBannerVisibility: false,
			isLowestPriceVisibility: false,

			// Product Families
			productFamiliesGroup: [],

			// Badge Labels
			badgeLabels: [],

			// LOB/PLP settings
			categoryDisplayType: { value: CATEGORY_DISPLAY_TYPE.SEARCH_LIST, label: 'Search Page' },
			searchKeyword: null,
			lobPlpStatus: false,
			PRODUCT_LABEL_TYPES,
			CATEGORY_DISPLAY_TYPE_OPTIONS,
			CATEGORY_DISPLAY_TYPE,

			localCategoryContentItems: [],

			isDrag: false,
		};
	},
	computed: {
		...mapState('categories', {
			list: 'list',
			create: 'create',
		}),
		...mapGetters({
			categoryLists: 'categories/categoryLists',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		isCreating() {
			return this.create.isLoading;
		},

		// Categories dropdown options
		categoryParentLevelOneOptions() {
			return this.categoryLists.map(transformedCategoryDropdownOption);
		},
		categoryParentLevelTwoOptions() {
			if (this.categoryParentLevelOne) {
				const id = this.categoryParentLevelOne.value;
				const levelOneItem =
					this.categoryLists.find((category) => category.id === id) ||
					{};
				const list = levelOneItem.children || [];

				return list.map(transformedCategoryDropdownOption);
			}
			return [];
		},

		isParentSelectable() {
			return this.level !== CATEGORY_LEVEL.ONE;
		},
		isLevelOne() {
			return this.level === CATEGORY_LEVEL.ONE;
		},
		isLevelThree() {
			return this.level === CATEGORY_LEVEL.THREE;
		},
		isValidCategoryParentLevelOne() {
			const isError = pathOr(false, [
				'$v',
				'categoryParentLevelOne',
				'$error',
			])(this);
			return isError ? false : null;
		},
		validatecategoryParentLevelOneMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'categoryParentLevelOne',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidCategoryParentLevelTwo() {
			const isError = pathOr(false, [
				'$v',
				'categoryParentLevelTwo',
				'$error',
			])(this);
			return isError ? false : null;
		},
		validatecategoryParentLevelTwoMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'categoryParentLevelTwo',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		// For submit
		categoryParentId() {
			if (this.isParentSelectable && this.isLevelThree) {
				return pathOr(null, ['categoryParentLevelTwo', 'value'])(this);
			} else if (this.isParentSelectable) {
				return pathOr(null, ['categoryParentLevelOne', 'value'])(this);
			}
			return null;
		},
		isValidName() {
			const isError = pathOr(false, ['$v', 'name', '$error'])(this);
			return isError ? false : null;
		},
		validateNameMessage() {
			const validateRequired = pathOr(false, ['$v', 'name', 'required'])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidNameTH() {
			const isError = pathOr(false, ['$v', 'nameTH', '$error'])(this);
			return isError ? false : null;
		},
		validateNameTHMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'nameTH',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidSlug() {
			const isError = pathOr(false, ['$v', 'slug', '$error'])(this);
			return isError ? false : null;
		},
		validateSlugMessage() {
			const validateRequired = pathOr(false, ['$v', 'slug', 'required'])(this);
			const validateFormat = pathOr(false, ['$v', 'slug', 'format'])(this);
			const validateDuplicate = pathOr(false, [
				'$v',
				'slug',
				'duplicate',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			} else if (!validateFormat) {
				return this.$t('page.categories.create.slugFormat');
			} else if (!validateDuplicate) {
				return this.$t('page.categories.create.duplicateSlug');
			}
			return null;
		},
		slugHelperText() {
			return this.isValidSlug !== false
				? this.$t('page.categories.create.slugFormat')
				: '';
		},
		isNewText() {
			const text = mappingVisibility[this.isNew] || '';
			return this.$t(text);
		},
		visibleViewAllText() {
			const text = mappingVisibility[this.isVisibleViewAll] || '';
			return this.$t(text);
		},
		statusText() {
			const text = mappingStatus[this.status] || '';
			return this.$t(text);
		},
		visibilityText() {
			const text = mappingVisibility[this.visibility] || '';
			return this.$t(text);
		},
		navbarVisibilityText() {
			const text = mappingVisibility[this.navbarVisibility] || '';
			return this.$t(text);
		},
		visibilityTradeInText() {
			const text = mappingVisibility[this.tradeInTruemoveVisibility] || '';
			return this.$t(text);
		},
		expandText() {
			const text = mappingExpand[this.isExpand] || '';
			return this.$t(text);
		},
		bannerVisibilityText() {
			const text = mappingVisibility[this.isBannerVisibility] || '';
			return this.$t(text);
		},
		lowestPriceVisibilityText() {
			const text = mappingVisibility[this.isLowestPriceVisibility] || '';
			return this.$t(text);
		},
		// Compare
		emptyClasses() {
			return !this.htmlContents.length ?
				'd-flex align-items-center justify-content-center'
				: '';
		},
	},
	created() {
		this.getCategories();
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			getCategories: 'categories/getCategories',
			postCategory: 'categories/postCategory',
			clearError: 'categories/clearCreateError',
		}),

		handleSelectedLevel(level) {
			this.clearError();

			// Should clear parent id value
			if (level === CATEGORY_LEVEL.ONE) {
				this.categoryParentLevelOne = null;
				this.categoryParentLevelTwo = null;
			} else if (level === CATEGORY_LEVEL.TWO) {
				this.categoryParentLevelTwo = null;
			}
		},
		handleSelectedCategoryParentLevelOne() {
			this.clearError();

			// Should clear parent id level 2 value
			this.categoryParentLevelTwo = null;
		},
		handleUpdateIsNew(value) {
			this.isNew = value;
			this.clearError();
		},
		handleUpdateStatus(value) {
			this.status = value;
			this.clearError();

			// When set status to false, should set visibility to false too
			if (value === false) {
				this.handleUpdateVisibility(false);
				this.handleUpdateTradeInTruemoveVisibility(false);
				this.handleUpdateNavbarVisibility(false);
			}
		},
		handleUpdateVisibility(value) {
			this.visibility = value;
			this.clearError();
		},
		handleUpdateIsVisibleViewAll(value) {
			this.isVisibleViewAll = value;
			this.clearError();
		},
		handleUpdateNavbarVisibility(value) {
			this.navbarVisibility = value;
			this.clearError();
		},
		handleUpdateTradeInTruemoveVisibility(value) {
			this.tradeInTruemoveVisibility = value;
			this.clearError();
		},
		handleUpdateBannerVisibility(value) {
			this.isBannerVisibility = value;
			this.clearError();
		},
		handleUpdateLowestPriceVisibility(value) {
			this.isLowestPriceVisibility = value;
			this.clearError();
		},
		sortHtmlContents() {
			if (this.isDrag) {
				this.htmlContents.forEach((content, index) => {
					content.priority = index;
				});
			}
		},

		async handleSubmit() {
			this.$v.$touch();
			if (!this.$v.$invalid) {
				this.sortHtmlContents();

				await this.postCategory({
					parent_id: this.categoryParentId,
					name_en: this.name,
					name_th: this.nameTH,
					slug: this.slug,
					custom_url: this.customUrl ? this.customUrl : null, // if is empty string change it to null
					is_new: this.isNew,
					is_active: this.status,
					is_visible: this.visibility,
					is_visible_view_all: this.isVisibleViewAll,
					is_navbar_visible: this.navbarVisibility,
					is_trade_in_truemove_visible: this.tradeInTruemoveVisibility,
					media: {
						banners: [],
						icons: this.icon ? [this.icon.id] : [],
					},
					banners: this.banners?.map((banner, index) => {
						return {
							link: banner.link,
							desktop_file_id: banner.desktopFile?.id,
							desktop_en_file_id: banner.desktopENFile?.id,
							mobile_file_id: banner.mobileFile?.id,
							mobile_en_file_id: banner.mobileENFile?.id,
							is_active: banner.isActive,
							order: index,
							start_at: banner.startDate ? convertDateTimeToUTC(banner.startDate, banner.startTime ?? '00:00') : null,
							end_at: banner.endDate ? convertDateTimeToUTC(banner.endDate, banner.endTime ?? '23:59') : null,
						};
					}),
					description_top: this.descriptionTop,
					description_bottom: this.descriptionBottom,
					seo: {
						title_en: this.metaTitle,
						title_th: this.metaTitleTH,
						description_en: this.metaDescription,
						description_th: this.metaDescriptionTH,
						keyword_en: this.metaKeyword,
						keyword_th: this.metaKeywordTH,
					},
					commission: this.commission,
					banner_link: null,
					compare_text_en: this.compareEN,
					compare_text_th: this.compareTH,
					compare_description_en: this.compareDescriptionEN,
					compare_description_th: this.compareDescriptionTH,
					html_contents: this.htmlContents?.map((content) => {
						return {
							id: content.id,
							title_en: content.titleEN,
							title_th: content.titleTH,
							content_en: content.contentEN,
							content_th: content.contentTH,
							priority: content.priority,
							is_active: content.isActive,
						};
					}),
					is_description_top_expanded: this.isExpand,
					is_lowest_price_visible: this.isLowestPriceVisibility,
					is_banner_visible: this.isBannerVisibility,
					product_family_ids: this.productFamiliesGroup.map((item) => item.id),

					// LOB/PLP
					line_product_list: {
						is_active: false,
						is_visible: false,
						title_en: '',
						title_th: '',
						description_en: '',
						description_th: '',
						media: {
							desktop_file_id: null,
							mobile_file_id: null,
						},
					},
					display_type: this.categoryDisplayType?.value || CATEGORY_DISPLAY_TYPE.SEARCH_LIST,
					search_keyword: this.searchKeyword,
					category_contents: this.localCategoryContentItems.map((item, index) => {
						return {
							title_en: item.titleEN,
							title_th: item.titleTH,
							sub_title_en: item.subTitleEN,
							sub_title_th: item.subTitleTH,
							display_lowest_price_type: item.displayLowestPriceType,
							is_lowest_price_visible: item.isLowestPriceVisible,
							short_description_en: item.shortDescriptionEN,
							short_description_th: item.shortDescriptionTH,
							description_en: item.descriptionEN,
							description_th: item.descriptionTH,
							type: item.type, // product or category
							type_id: item.typeId,
							destination_type: item.destinationType, // default or search
							search_keyword: item.searchKeyword,
							highlight: item.isHighlight,
							priority: index + 1,
							is_active: item.isActive,
							start_at: item.startDate ? convertDateTimeToUTC(item.startDate, item.startTime ?? '00:00') : null,
							end_at: item.endDate ? convertDateTimeToUTC(item.endDate, item.endTime ?? '23:59') : null,
							use_default_button: Boolean(item.isActiveButton),
							custom_button_title_th: item.buttonNameTh,
							custom_button_title_en: item.buttonNameEn,
							custom_url: item.customLink,
							label_ids: item.badgeLabels.map((badge) => ({
								label_id: badge.id,
								is_label_visible: badge.isLabelVisible ? 1 : 0,
							})),
							media: {
								desktop_file_id: item.imageDesktop.id,
								mobile_file_id: item.imageMobile.id,
							},
							html_contents: item.isContentHTMLActive ?
								[{
									title_en: item.btnTitleEN,
									title_th: item.btnTitleTH,
									content_en: item.contentEN,
									content_th: item.contentTH,
									priority: 1,
									is_active: item.isContentHTMLActive,
								}] : []
							,
						};
					}),
				});

				// Redirect to category list page after created
				if (this.create.isSuccess) {
					this.$router.push({ name: 'CategoryLists' });

					// Show toast if api return any errors
				} else {
					let content = this.$t('global.errorMessage');
					if (this.create.fieldErrors.slug) {
						content = this.$t('page.categories.create.duplicateSlug');
					}

					this.showToast({
						content,
						header: this.$t('global.errorMessageTitleCreate'),
						type: 'danger',
					});
				}

				scrollToTop();
			}
		},
		handleAutoFillMetaTitleAndSlug() {
			if (!this.metaTitle) {
				this.metaTitle = this.name;
			}

			if (!this.slug) {
				this.slug = slugify(this.name, {
					lower: true,
					strict: true,
				});
				this.$v.slug.$touch();
			}
		},
		handleAutoFillMetaTitleTH() {
			if (!this.metaTitleTH) {
				this.metaTitleTH = this.nameTH;
			}
		},
		handleUpdateExpand(value) {
			this.isExpand = value;
			this.clearError();
		},
		handleProductFamiliesGroupListChange(list) {
			this.productFamiliesGroup = list;
		},
		handleUpdateLobPlpStatus(value) {
			this.lobPlpStatus = value;
		},
		openProductBadgeModal(type) {
			this.$refs['modal-badge-label'].open(type);
		},
		// Compare
		handleOnEditCompare(data, index) {
			this.$refs['modal-category-compare'].open(data, index);
		},
		moveCompareToTop(index) {
			this.htmlContents.unshift(this.htmlContents.splice(index, 1)[0]);
		},
		handleCompareOrder(sortedList = []) {
			this.htmlContents = sortedList;
			this.isDrag = true;
		},
		handleCategoryCompareChange(data, isChangePriority) {
			if (data.index !== null) {
				this.htmlContents[data.index] = data;
			} else {
				this.htmlContents = [
					...this.htmlContents,
					data,
				];
			}
			if (isChangePriority) {
				this.isDrag = false;
			}
			this.resetHtmlContents();
		},
		handleOnDeleteCompare(index) {
			this.htmlContents = this.htmlContents.filter((_, i) => i !== index);
			this.resetHtmlContents();
		},
		resetHtmlContents() {
			const htmlContentsTemp = this.htmlContents;
			this.htmlContents = [];
			this.$nextTick(() => {
				this.htmlContents = htmlContentsTemp;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
hr {
	margin: rem(40) 0;
}

.level-label {
	margin-bottom: rem(4);
}

.category-display-label {
	flex-grow: 1;
}

.category-display-text {
	min-width: rem(85);
}

.toggle-label {
	width: rem(200);
	margin: 0 rem(24) 0 0;

	&.-is-expand-content {
		width: auto !important;
	}
}

.toggle-status,
.toggle-visibility {
	margin-right: rem(12);
}

.btn-cancel,
.btn-submit {
	min-width: rem(132);
}

.row-parent-category {
	// margin left, right come from .row
	margin-top: rem(40);
	margin-bottom: rem(-40);
}

.margin-bottom-0 {
	// .margin-bottom-0
	&-important {
		margin-bottom: rem(0) !important;
	}
}

.margin-bottom-32 {
	margin-bottom: rem(32);
}

.form-group {
	margin-bottom: rem(40);
}

.compare-list {
	border: rem(2) solid $color-gray-100;
	background-color: $color-gray-100;
	border-radius: rem(2);
	list-style: none;
	min-height: rem(272);
}
</style>
